@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body,
p,
a {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: #34495E;
}

p {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  margin: 0px !important;
}

.box {
  background-color: #fff;
  padding: 30px;
  border-radius: 30px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.349);
  overflow-y: scroll;
}
.box::-webkit-scrollbar {
  width: 0px;
  /* Width of the scrollbar */
}

.main-heading {
  font-size: 28px;
  font-weight: 600;
  color: #06121D;
}

.sub-heading {
  color: #125688;
  margin: 15px 0px;
  font-size: 18px;
  font-weight: 600;
}

input,
select {
  padding: 5px 10px !important;
}

button {
  border: none;
}

.fix-height {
  height: calc(100vh - 60px);
  overflow-y: scroll;
}
.fix-height::-webkit-scrollbar {
  width: 2px;
}
.fix-height::-webkit-scrollbar-thumb {
  background-color: #ECF0F1;
}

.form-row {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
.form-row .form-group {
  width: 100%;
}

.login-page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login-page .login-inner {
  width: 40%;
}
.login-page .login-inner .login-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login-page .login-inner .login-header img {
  width: 150px;
}

.dashboard-page-main {
  display: flex;
  justify-content: space-between;
}
.dashboard-page-main .dashboard-page {
  width: 60%;
}
.dashboard-page-main .dashboard-page .first-row {
  display: flex;
  justify-content: space-between;
}
.dashboard-page-main .dashboard-page .first-row .box {
  width: 48%;
}
.dashboard-page-main .dashboard-dev-notes {
  width: 35%;
}
.dashboard-page-main .box ul {
  margin: 0px;
  padding: 0px;
}

.App {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: #ECF0F1;
}
.App .mainContent {
  width: 100%;
  margin: 30px 50px;
}

.sidebar {
  background-color: #06121D;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sidebar .logo {
  width: 200px;
  border-bottom: 2px solid #fff;
  padding: 10px 10px;
}
.sidebar .logo img {
  width: 100%;
}
.sidebar ul {
  padding: 20px;
  height: 60vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: -99;
  /* Style the scrollbar */
  /* Style scrollbar track */
  /* Style scrollbar thumb */
  /* Hide scrollbar buttons (arrows) */
}
.sidebar ul::-webkit-scrollbar {
  width: 0px;
  z-index: -99;
  /* Width of the scrollbar */
}
.sidebar ul::-webkit-scrollbar-track {
  background-color: transparent;
  /* Track color */
}
.sidebar ul::-webkit-scrollbar-thumb {
  background-color: #E67E22;
  /* Thumb color */
  border-radius: 10px;
  /* Rounded corners */
  /* Height of the scrollbar thumb */
  z-index: -99;
}
.sidebar ul::-webkit-scrollbar-button {
  display: none;
}
.sidebar ul li .nav-item {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  text-align: left;
  position: relative;
}
.sidebar ul li .nav-item .i {
  font-size: 24px;
  margin-right: 10px;
  color: #fff;
  width: 24px;
}
.sidebar ul li .nav-item:hover {
  color: #E67E22;
}
.sidebar ul li .nav-item:hover .i {
  color: #E67E22;
}
.sidebar ul li .nav-item.active {
  color: #E67E22;
}
.sidebar ul li .nav-item.active .i {
  color: #E67E22;
}
.sidebar ul li .nav-item.active::after {
  content: "";
  width: 40px;
  height: 40px;
  background-color: #ECF0F1;
  border-radius: 50%;
  position: absolute;
  right: -40px;
  border: 3px solid #E67E22;
  transition: 3s ease-in-out;
}
.sidebar .settings {
  padding: 20px;
  border-top: 3px solid #fff;
}
.sidebar .settings button {
  display: block;
  border: none;
  background-color: transparent;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  text-align: left;
}
.sidebar .settings button .i {
  margin-right: 10px;
}
.sidebar .settings button:hover::after, .sidebar .settings button.active::after {
  content: "";
  z-index: 9;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  right: -40px;
  border: 3px solid #2ECC71;
  transition: 3s ease-in-out;
}

.enrollment-page {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.enrollment-page .enrollment-form-section {
  width: 55%;
  height: calc(100vh - 60px);
  overflow: hidden;
}
.enrollment-page .enrollment-form-section form {
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 50px;
}
.enrollment-page .enrollment-form-section form::-webkit-scrollbar {
  width: 0px;
  /* Width of the scrollbar */
}
.enrollment-page .student-preview {
  width: 42%;
}
.enrollment-page .student-preview .profile-item {
  align-items: center;
  gap: 50px;
}
.enrollment-page .student-preview .profile-item .profile img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  object-fit: cover;
}
.enrollment-page .student-preview .profile-item .profile-details h3 {
  font-size: 20px;
}
.enrollment-page .student-preview .preview-item p {
  margin-bottom: 10px;
}

.users-page {
  display: flex;
  justify-content: space-between;
}
.users-page .user-list {
  width: 42%;
}
.users-page .user-list .search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.users-page .user-list .search input {
  border-radius: 20px;
  padding-left: 20px !important;
  width: 60%;
}
.users-page .user-list .table-container {
  width: 100%;
  margin-top: 30px;
  height: 65vh;
  overflow-y: scroll;
}
.users-page .user-list .table-container::-webkit-scrollbar {
  width: 2px;
}
.users-page .user-list .table-container::-webkit-scrollbar-thumb {
  background-color: #ECF0F1;
}
.users-page .user-list .table-container table {
  width: 100%;
}
.users-page .user-list .table-container table tr {
  margin: 20px;
}
.users-page .user-list .table-container table tr th {
  font-weight: 900;
}
.users-page .user-list .table-container table tr td {
  padding: 10px 0px;
}
.users-page .admin-side {
  width: 55%;
  height: 85vh;
  overflow-y: scroll;
}
.users-page .admin-side::-webkit-scrollbar {
  width: 2px;
}
.users-page .admin-side::-webkit-scrollbar-thumb {
  background-color: #ECF0F1;
}
.users-page .admin-side .add-admin-user {
  margin-bottom: 30px;
}
.users-page .admin-side .box {
  box-shadow: none;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open .ReactModal__Content.ReactModal__Content--after-open {
  padding: 0px !important;
  height: 100% !important;
  background: transparent !important;
  border: none !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open .ReactModal__Content.ReactModal__Content--after-open .modal-content {
  background-color: #fff;
  width: 500px;
  padding: 40px 30px;
  border-radius: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ReactModal__Overlay.ReactModal__Overlay--after-open .ReactModal__Content.ReactModal__Content--after-open .modal-content h2,
.ReactModal__Overlay.ReactModal__Overlay--after-open .ReactModal__Content.ReactModal__Content--after-open .modal-content p {
  text-align: center;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open .ReactModal__Content.ReactModal__Content--after-open .modal-content .btn-row {
  display: flex;
  justify-content: space-evenly;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open .ReactModal__Content.ReactModal__Content--after-open .modal-content .btn-row button {
  width: 20%;
  float: left;
}

.class-page {
  display: flex;
  justify-content: space-between;
}
.class-page .class-list-section {
  width: 40%;
}
.class-page .class-list-section .class-list {
  padding: 30px 0px;
}
.class-page .class-list-section .class-list ul {
  padding: 0px;
  margin: 0px;
}
.class-page .class-overview {
  width: 55%;
}
.class-page ul {
  margin: 0px;
  padding: 0px;
}

.pop-up-edit {
  width: 500px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.students-page {
  display: flex;
  justify-content: space-between;
}
.students-page .fix-height {
  height: calc(100vh - 60px);
  overflow-y: scroll;
}
.students-page .fix-height::-webkit-scrollbar {
  width: 2px;
}
.students-page .fix-height::-webkit-scrollbar-thumb {
  background-color: #ECF0F1;
}
.students-page .student-list {
  width: 55%;
}
.students-page .student-list .search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.students-page .student-list .search input {
  width: 60%;
  border-radius: 20px;
  padding-left: 20px !important;
}
.students-page .student-list ul {
  padding: 0px;
}
.students-page .student-list ul li {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.students-page .student-list ul li span {
  width: 20%;
  text-align: left;
}
.students-page .student-list ul li span img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  object-fit: cover;
}
.students-page .student-preview {
  width: 42%;
}
.students-page .student-preview img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  object-fit: cover;
}
.students-page .student-preview span {
  font-weight: 700;
  margin-right: 10px;
}

.popup-editstudent form .form-row {
  display: flex;
  gap: 10px;
}
.popup-editstudent form .form-row .from-group {
  width: 100%;
}

.calendar-page .calendar-row {
  display: flex;
  justify-content: space-between;
}
.calendar-page .calendar-row .choose-date {
  width: 55%;
  height: 100%;
}
.calendar-page .calendar-row .choose-date .react-calendar {
  width: 100%;
}
.calendar-page .calendar-row .choose-date .add-event {
  margin-top: 30px;
}
.calendar-page .calendar-row .choose-date .add-event h3 {
  margin-bottom: 20px;
}
.calendar-page .calendar-row .event-list {
  width: 42%;
  height: calc(100vh - 60px);
  overflow-y: scroll;
}
.calendar-page .calendar-row .event-list::-webkit-scrollbar {
  width: 5px;
}
.calendar-page .calendar-row .event-list::-webkit-scrollbar-thumb {
  background-color: #ECF0F1;
}
.calendar-page .calendar-row .event-list ul {
  padding: 0px;
}
.calendar-page .calendar-row .event-list ul .event-item .event-date {
  font-weight: 800;
  margin-right: 10px;
}

.billing-page-main {
  display: flex;
  justify-content: space-between;
}
.billing-page-main .billing-page {
  width: 50%;
}
.billing-page-main .pdf-info {
  width: 45%;
}

.fee-page {
  display: flex;
  justify-content: space-between;
}
.fee-page .data-coll {
  width: 45%;
}
.fee-page .data-pdf {
  width: 50%;
}
.fee-page .data-pdf embed {
  height: 70vh !important;
}

.balance-page {
  /* Optional: Style the table to maintain headers and columns */
}
.balance-page table {
  width: 100%;
  /* Make sure the table takes up the full width */
  /* Makes table a block element */
  overflow-x: auto;
  /* Enables horizontal scrolling */
  max-height: 60vh;
  /* Limits the height of the table container */
  overflow-y: auto;
  /* Enables vertical scrolling if content exceeds 60vh */
}


