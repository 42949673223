.billing-page-main {
  display: flex;
  justify-content: space-between;

  .billing-page {
    width: 50%;
  }

  .pdf-info {
    width: 45%;
  }
}