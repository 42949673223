.login-page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;


  .login-inner {
    width: 40%;


    .login-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 150px;
      }
    }
  }
}