//Fonts
$poppins: "Poppins", sans-serif;
$inter: "Inter", sans-serif;



// common variale 
$facebook: #3b5998;
$twitter: #55acee;
$gplus: #d34836;
$linkedin: #0077b5;
$skype: #0078ca;
$youtube: #cc181e;
$instagram: #125688;

//website  Colors variable
$primaryColor: #06121D;
$secondaryColor: #34495E;
$SoftPrimaryColor: #ECF0F1;
$black: #000000;
$white: #fff;
$acent: #2ECC71;
$danger: #E67E22;