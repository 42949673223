// app.js 
.App {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: $SoftPrimaryColor;

  .mainContent {
    width: 100%;
    margin: 30px 50px;
    // background-color: $SoftPrimaryColor;
  }

}


// app.js end 



.sidebar {
  background-color: $primaryColor;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logo {
    width: 200px;
    border-bottom: 2px solid $white;
    padding: 10px 10px;

    img {
      width: 100%;
    }
  }

  ul {
    padding: 20px;
    height: 60vh;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: -99;


    /* Style the scrollbar */
    &::-webkit-scrollbar {
      width: 0px;
      z-index: -99;
      /* Width of the scrollbar */
    }

    /* Style scrollbar track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
      /* Track color */

    }

    /* Style scrollbar thumb */
    &::-webkit-scrollbar-thumb {
      background-color: $danger;
      /* Thumb color */
      border-radius: 10px;
      /* Rounded corners */
      /* Height of the scrollbar thumb */
      z-index: -99;

    }

    /* Hide scrollbar buttons (arrows) */
    &::-webkit-scrollbar-button {
      display: none;


    }

    li {



      .nav-item {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 30px;
        color: $white;
        text-decoration: none;
        display: flex;

        align-items: center;
        text-align: left;
        position: relative;

        .i {
          font-size: 24px;
          margin-right: 10px;
          color: $white;
          width: 24px;
        }

        &:hover {
          color: $danger;

          .i {
            color: $danger;
          }
        }

        &.active {
          color: $danger;

          .i {
            color: $danger;
          }

          &::after {
            content: "";
            width: 40px;
            height: 40px;
            background-color: $SoftPrimaryColor;
            border-radius: 50%;
            position: absolute;
            right: -40px;
            border: 3px solid $danger;
            transition: 3s ease-in-out;


          }


        }

      }


    }
  }

  .settings {
    padding: 20px;
    border-top: 3px solid$white;

    button {
      display: block;
      border: none;
      background-color: transparent;
      color: $white;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 10px;
      position: relative;
      width: 100%;
      text-align: left;

      .i {
        margin-right: 10px;
      }

      &:hover,
      &.active {
        &::after {
          content: "";
          z-index: 9;
          width: 40px;
          height: 40px;
          background-color: $white;
          border-radius: 50%;
          position: absolute;
          right: -40px;
          border: 3px solid $acent;
          transition: 3s ease-in-out;

        }


      }
    }
  }
}