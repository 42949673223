.calendar-page {
  .calendar-row {
    display: flex;
    justify-content: space-between;

    .choose-date {
      width: 55%;
      height: 100%;

      .react-calendar {
        width: 100%;

        .react-calendar__navigation {}

        .react-calendar__viewContainer {}
      }

      .add-event {
        margin-top: 30px;

        h3 {
          margin-bottom: 20px;
        }
      }

    }

    .event-list {
      width: 42%;
      height: calc(100vh - 60px);
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $SoftPrimaryColor;
      }

      ul {
        padding: 0px;

        .event-item {
          .event-date {
            font-weight: 800;
            margin-right: 10px;
          }
        }
      }
    }
  }
}