.balance-page {
  table {
    width: 100%;
    /* Make sure the table takes up the full width */
    /* Makes table a block element */
    overflow-x: auto;
    /* Enables horizontal scrolling */
    max-height: 60vh;
    /* Limits the height of the table container */
    overflow-y: auto;
    /* Enables vertical scrolling if content exceeds 60vh */
  }

  /* Optional: Style the table to maintain headers and columns */
  // th,
  // td {
  //   white-space: nowrap;
  //   /* Prevents text from wrapping, useful for scrolling */
  // }
}