.enrollment-page {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .enrollment-form-section {
    width: 55%;

    height: calc(100vh - 60px);
    overflow: hidden;



    form {
      height: 100%;
      overflow-y: scroll;
      padding-bottom: 50px;

      &::-webkit-scrollbar {
        width: 0px;

        /* Width of the scrollbar */
      }






    }

  }

  .student-preview {
    width: 42%;




    .profile-item {

      align-items: center;
      gap: 50px;

      .profile {
        img {
          width: 85px;
          height: 85px;
          border-radius: 50%;
          object-fit: cover;

        }
      }

      .profile-details {
        h3 {
          font-size: 20px;
        }


      }



    }

    .preview-item {
      p {
        margin-bottom: 10px;
      }
    }
  }

}