.class-page {
  display: flex;
  justify-content: space-between;

  .class-list-section {
    width: 40%;

    .class-list {
      padding: 30px 0px;

      ul {
        padding: 0px;
        margin: 0px;
      }
    }
  }

  .class-overview {
    width: 55%;
  }

  ul {
    margin: 0px;
    padding: 0px;
  }


}

.pop-up-edit {
  width: 500px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}