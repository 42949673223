.fee-page {
  display: flex;
  justify-content: space-between;

  .data-coll {
    width: 45%;
  }

  .data-pdf {
    width: 50%;

    embed {
      height: calc(70vh) !important;
    }
  }
}