.students-page {
  display: flex;
  justify-content: space-between;


  .fix-height {
    height: calc(100vh - 60px);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $SoftPrimaryColor;
    }
  }


  .student-list {
    width: 55%;

    .search {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      input {
        width: 60%;
        border-radius: 20px;
        padding-left: 20px !important;

      }
    }

    ul {
      padding: 0px;

      li {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          width: 20%;
          text-align: left;

          img {
            width: 85px;
            height: 85px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
    }
  }

  .student-preview {
    width: 42%;

    img {
      width: 85px;
      height: 85px;
      border-radius: 50%;
      object-fit: cover;
    }

    span {
      font-weight: 700;
      margin-right: 10px;
    }

  }


}




// student pop up form - modal
.popup-editstudent {
  form {
    .form-row {
      display: flex;
      gap: 10px;

      .from-group {
        width: 100%;
      }
    }
  }
}

.popup-editstudent {}