.box {
  background-color: $white;
  padding: 30px;
  border-radius: 30px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.349);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0px;

    /* Width of the scrollbar */
  }
}

.main-heading {
  font-size: 28px;
  font-weight: 600;
  color: $primaryColor;
}

.sub-heading {
  color: $instagram;
  margin: 15px 0px;
  font-size: 18px;
  font-weight: 600;
}

// form 

input,
select {
  padding: 5px 10px !important;
}

button {
  border: none;
}

.fix-height {
  height: calc(100vh - 60px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $SoftPrimaryColor;
  }
}

.form-row {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  .form-group {
    width: 100%;


  }
}