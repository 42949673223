.users-page {
  display: flex;
  justify-content: space-between;


  .user-list {
    width: 42%;


    .search {
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        border-radius: 20px;
        padding-left: 20px !important;
        width: 60%;
      }
    }

    .table-container {
      width: 100%;
      margin-top: 30px;
      height: 65vh;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $SoftPrimaryColor;
      }


      table {
        width: 100%;




        tr {
          margin: 20px;


          th {
            font-weight: 900;
            // padding: 10px;
          }

          td {
            padding: 10px 0px;


          }
        }

      }
    }

  }

  .admin-side {
    width: 55%;

    height: 85vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $SoftPrimaryColor;
    }

    .add-admin-user {
      margin-bottom: 30px;




    }

    .box {
      box-shadow: none;

    }
  }
}



// conformation update --- modal 
.ReactModal__Overlay.ReactModal__Overlay--after-open {

  background-color: rgb(0 0 0 / 80%) !important;

  .ReactModal__Content.ReactModal__Content--after-open {
    padding: 0px !important;
    height: 100% !important;
    background: transparent !important;
    border: none !important;

    .modal-content {
      background-color: $white;
      width: 500px;
      padding: 40px 30px;
      border-radius: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      h2,
      p {
        text-align: center;
      }

      .btn-row {
        display: flex;
        justify-content: space-evenly;

        button {
          width: 20%;
          float: left;
        }
      }

    }
  }
}