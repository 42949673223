.dashboard-page-main {
  display: flex;
  justify-content: space-between;

  .dashboard-page {
    width: 60%;

    .first-row {
      display: flex;
      justify-content: space-between;

      .box {
        width: 48%;
      }
    }
  }

  .dashboard-dev-notes {
    width: 35%;
  }

  .box {
    ul {
      margin: 0px;
      padding: 0px;
    }
  }
}